<template>
  <ep-layout @tabClicked="changeTab" class="el-body documents">
    <div class="el-dialog" v-if="moveDialogOpen"
         @click="closeMoveDialog()"
         :aria-hidden="modalVisible">
      <div class="el-dialog__container el-dialog__container--large" @click.stop>
        <div class="el-panel">
          <el-toolbar :sticky="true">
            <button
              v-if="modalBreadcrumbs.length > 1"
              class="el-icon-button el-icon-button--big icon-previous_page"
              @click="goToModalPrevious()"
            ></button>
            <h3 class="el-title">
              {{ textTitle }} {{ textFiles }}
            </h3>
          </el-toolbar>

          <div class="el-dialog__panel ep-left-align">
            <!-- breadcrumb -->
            <span
              class="ep-breadcrumb"
              :class="{ active: index === (modalBreadcrumbs.length - 1) }"
              v-for="(item, index) in modalBreadcrumbs"
              :key="item.id"
              @click="goToModalFolder(item)"
            >
              <span class="ep-breadcrumb__chevron" v-if="index > 0">></span>
              <template v-if="item.code === 'notClassified'">
                {{ $t('documents.categories.notClassified') }}
              </template>
              <template v-else>{{ item.name }}</template>
            </span>
            <el-table>
              <el-table-header>
                <el-table-row>
                  <el-table-head></el-table-head>
                  <el-table-head :main="true">{{ $t('documents.name') }}</el-table-head>
                  <el-table-head></el-table-head>
                </el-table-row>
              </el-table-header>
              <el-table-body>
                <el-table-row
                  v-for="folder in modalFolders
                    .filter(x => x.type === 'folder')"
                  :key="folder.folderId"
                  @click.native="goToModalNext(folder)"
                >
                  <!-- icon -->
                  <el-table-cell data-type="input">
                    <!-- eslint-disable-next-line -->
                    <span :class="['icon-folder', 'icon--table--orange']"></span>
                  </el-table-cell>
                  <!-- folder name -->
                  <el-table-cell :main="true">
                    <span v-if="folder.folderId === 'notClassified'">
                      {{ $t('documents.categories.notClassified') }}
                    </span>
                    <span v-else-if="folder !== fileRenamed">{{ folder.name }}</span>
                    <!-- eslint-disable-next-line -->
                    <form v-else @click.stop @submit.prevent="rename(folder, $refs.renameFolder[0].value)">
                      <div class="el-input-group el-input-group--default-size">
                        <input
                          ref="renameFolder"
                          type="text"
                          class="el-input-group__input"
                          :value="folder.name"
                          :placeholder="$t('documents.rename.placeholder')"
                          required
                          autofocus
                        />
                      </div>
                      <button class="el-button">
                        <span class="icon-save"></span>
                      </button>
                      <button
                        class="el-button el-button--inverted"
                        @click.prevent="fileRenamed = undefined"
                      >
                        <span class="icon-close"></span>
                      </button>
                    </form>
                  </el-table-cell>
                  <el-table-cell data-type="input" @click.native.stop>
                    <el-rectangle-button
                              :class="{ 'ep-rectangle-button-active' :
                        folder.folderId === selectedMoveFolder }"
                      @click.native="updateSelectedMoveFolder(folder); moveElement()"
                      v-if="folder.folderId !== 'notClassified'"
                      :title="$t('documents.modal.move.selected') + ' vers ' + folder.name"
                    >
                    {{ $t('documents.modal.move.action.move') }}
                    </el-rectangle-button>
                  </el-table-cell>
                </el-table-row>
              </el-table-body>
            </el-table>
          </div>

          <el-toolbar :bottom="true">
            <button
              class="el-button el-button--inverted" @click="closeMoveDialog()">
              {{ $t('button.cancel') }}
            </button>
          </el-toolbar>
        </div>
      </div>
    </div>
    <el-toolbar :sticky="true" class="mt-3" :aria-hidden="modalVisible">
      <div class="flex w-full content-center items-center flex-wrap mt-3">
        <el-toolbar-section>
          <button
            v-if="breadcrumbs.length > 1"
            class="el-icon-button el-icon-button--big icon-previous_page"
            @click="goToPrevious()"
            :aria-label="$t('button.back')"
            :title="$t('button.back')"
          ></button>
          <h2 class="el-main-title">
            <template
              v-if="currentFolder.code === 'notClassified'"
            >{{ $t('documents.categories.notClassified') }}</template>
            <template v-else>{{ currentFolder.name }}</template>
          </h2>
        </el-toolbar-section>
        <el-toolbar-spacer />
        <el-toolbar-section >
          <el-rectangle-button-container
            :grey="true"
            >
            <el-rectangle-button
              @click.native="newFolderDialog = true"
              :title="$t('documents.newFolder.title')"
              :aria-label="$t('documents.newFolder.title') "
            >
              <span class="icon-create_folder"></span>
              <span aria-hidden="true"> {{ $t('documents.newFolder.title') }}</span>
            </el-rectangle-button>
            <!-- new folder fomr -->
            <!-- eslint-disable-next-line -->
            <form
              :class="['ep-dropdown__content', { 'ep-dropdown__content--active': newFolderDialog }]"
              @submit.prevent="createFolder()"
              @click.stop
              :aria-hidden="!newFolderDialog"
            >
              <div class="el-input-group el-input-group--default-size" >
                <input
                  class="el-input-group__input"
                  v-model="newFolderName"
                  type="text"
                  name="newfolder"
                  :placeholder="$t('documents.newFolder.placeholder')"
                  required
                />
              </div>
              <div class="el-input-group my-0" tabindex="-1">
                <button class="el-button e2e-validcreateFolder" tabindex="0" :title="$t('documents.newFolder.button.submit')">
                  <span class="icon-completed"></span>
                </button>
                <button class="el-button el-button--inverted"
                        @click.prevent="newFolderDialog = false" disabledtabindex="0" :title="$t('documents.sharing.create.button.cancel')">
                  <span class="icon-close"></span>
                </button>
              </div>
            </form>
          </el-rectangle-button-container>
          <div class="el-rectangle-button__container el-rectangle-button__container--grey ">
            <div class="el-rectangle-button">
              <input
                class="ep-input-group--upload el-rectangle-button   "
                type="file"
                ref="uploadFiles"
                accept=".pdf, .png, .jpg"
                multiple
                @change="handleFileUpload"
                aria-hidden="true"
              />
              <span class="icon-add_document"></span>
              {{ $t('documents.newDoc.title') }}
            </div>
          </div>
          <!-- </div> -->
        </el-toolbar-section>
        <el-toolbar-section>
          <div class="el-input-group el-input-group--default-size">
            <input
              type="text"
              v-model="search"
              :placeholder="$t('documents.search')"
              class="el-input-group__input el-input-group__input--search"
            />
          </div>
          <button
            :class="['el-icon-button', { 'icon-search': !search }, { 'icon-close': search }]"
            @click.prevent="search = ''"
            :title="$t('accessibility.launch.search')"
            :aria-label="$t('accessibility.launch.search')"
          ></button>
        </el-toolbar-section>
      </div>
      <div class="mt-4 flex w-full content-center items-center">
        <template v-for="(item, index) in breadcrumbs">
          <span
            v-if="breadcrumbs.length > 1 && index !== (breadcrumbs.length - 1)"
            class="ep-breadcrumb"
            :key="item.id"
            @click="goToFolder(item)"
          >
            <span :key="'chevron_' + item.id" class="ep-breadcrumb__chevron" v-if="index > 0">
              &nbsp;/&nbsp;
            </span>
            <template v-if="item.code === 'notClassified'">
              {{ $t('documents.categories.notClassified') }}
            </template>
            <template v-else>{{ item.name }}</template>
          </span>
          <span v-else :key="item.id">&nbsp;</span>
        </template>
      </div>
    </el-toolbar>

    <!-- table -->
    <el-table :aria-hidden="modalVisible">
      <el-table-header>
        <el-table-row class="h-16" >
          <el-table-head class="w-12" data-type="input">
            <!-- eslint-disable-next-line -->
            <input v-if="countFiles() > 0"
                   class="el-checkbox"
                   type="checkbox"
                   :checked="allSelected"
                   @click="selectAll()"
                   :title="$t('document.selected.all') "
                   :aria-label="$t('document.selected.all') "
            />
          </el-table-head>
          <el-table-head class="w-16" data-type="input"></el-table-head>
          <template v-if="selected.length">
            <!-- selected elment counter -->
            <el-table-head :main="true" >
            <div class="el-text">
              {{ $tc('documents.selected', selected.length) }}
            </div>
            </el-table-head>
            <el-table-head>
              <span style="visibility: hidden">{{ $t('documents.date') }}</span>
            </el-table-head>
            <el-table-head  data-type="button" class="text-right">
              <el-rectangle-button-container v-if="selected.length">
                <el-rectangle-button
                  @click.native="downloadMultipleFiles()"
                  :title="$t('documents.toolbar.button.download')"
                  :aria-label="$t('documents.toolbar.button.download')">
                  <span aria-hidden="true">{{ $t('documents.toolbar.button.download')}}</span>
                </el-rectangle-button>
                <el-rectangle-button-child tabindex="0" >
                  <el-rectangle-button
                    @click.native="share()"
                    :title="$t('documents.toolbar.button.share')"
                    :aria-label="$t('documents.toolbar.button.share') "
                    tabindex="0">
                    <span class="icon-share"></span>
                    {{ $t('documents.toolbar.button.share')}}
                  </el-rectangle-button>
                  <el-rectangle-button
                    @click.native="openMoveDialog()"
                    :title="$t('documents.toolbar.button.move')"
                    :aria-label="$t('documents.toolbar.button.move') ">
                    {{ $t('documents.toolbar.button.move')}}
                  </el-rectangle-button>
                  <el-rectangle-button
                    @click.native="markSelectedAsRead(true)"
                    :title="$t('documents.toolbar.button.markAsRead')"
                    :aria-label="$t('documents.toolbar.button.markAsRead') ">
                    <span class="icon-completed"></span>
                    {{ $t('documents.toolbar.button.markAsRead')}}
                  </el-rectangle-button>
                  <el-rectangle-button
                    @click.native="markSelectedAsRead(false)"
                    :title="$t('documents.toolbar.button.markAsUnread')"
                    :aria-label="$t('documents.toolbar.button.markAsUnread') ">
                    <span class="icon-close"></span>
                    {{ $t('documents.toolbar.button.markAsUnread')}}
                  </el-rectangle-button>
                  <el-rectangle-button
                    @click.native="showModal('deleteSelected')"
                    :title="$t('documents.toolbar.button.delete')"
                    :aria-label="$t('documents.toolbar.button.delete') ">
                    <span class="icon-supprime"></span>
                    {{ $t('documents.toolbar.button.delete') }}
                  </el-rectangle-button>
                </el-rectangle-button-child>
              </el-rectangle-button-container>
            </el-table-head>
          </template>
          <template v-else tabindex="0">
            <el-table-head :main="true">{{ $t('documents.name') }}</el-table-head>
            <el-table-head>{{ $t('documents.date') }}</el-table-head>
            <el-table-head data-type="button"></el-table-head>
          </template>
        </el-table-row>
      </el-table-header>
      <el-table-body v-if="files">
        <!-- folder -->
        <el-table-row
          v-for="folder in files.filter(x => x.type === 'folder')"
          :key="folder.folderId"
          class="ep-table__row h-16"
          @click.native="goToNext(folder)"
          tabindex="0"
        >
          <!-- checkbox -->
          <el-table-cell data-type="input"
                        >
          </el-table-cell>
          <!-- icon -->
          <el-table-cell data-type="input" aria-hidden="true">
            <!-- eslint-disable-next-line -->
            <span :class="['icon-folder', 'icon--table--orange', { 'icon--notif': folder.isNew }]" ></span>
          </el-table-cell>
          <!-- folder name -->
          <el-table-cell :main="true">
            <span v-if="folder !== fileRenamed">{{ folder.name }}</span>
            <!-- eslint-disable-next-line -->
            <form v-else @click.stop @submit.prevent="rename(folder, $refs.renameFolder[0].value)">
              <div class="el-input-group el-input-group--default-size">
                <input
                  ref="renameFolder"
                  type="text"
                  class="el-input-group__input"
                  :value="folder.name"
                  :placeholder="$t('documents.rename.placeholder')"
                  required
                  autofocus
                />
              </div>
              <button class="el-button">
                <span class="icon-save"></span>
              </button>
              <button
                class="el-button el-button--inverted"
                @click.prevent="fileRenamed = undefined"
              >
                <span class="icon-close"></span>
              </button>
            </form>
          </el-table-cell>
          <!-- folder date -->
          <el-table-cell>__</el-table-cell>
          <!-- folder buttons -->
          <el-table-cell data-type="button" @click.native.stop>
            <el-rectangle-button-container v-if="selected.length === 0">
              <el-rectangle-button
                @click.native="showModal('deleteFolder',folder)"
                tabindex="0"
                :title="$t('documents.toolbar.button.delete') + ' ' + folder.name"
                class="ep-button__focus"
              >{{ $t('documents.toolbar.button.delete') }}</el-rectangle-button>
              <el-rectangle-button-child :title="'Menu ' + folder.name"
                                            tabindex="0">
                <el-rectangle-button
                  @click.native="fileRenamed = folder"
                  :title="$t('documents.toolbar.button.rename') + ' ' + folder.name"
                  class="ep-button__focus"
                >{{ $t('documents.toolbar.button.rename') }}</el-rectangle-button>
                <el-rectangle-button @click.native="openMoveDialog(folder)"
                 :title="$t('documents.toolbar.button.move') + ' ' + folder.name"
                 class="ep-button__focus">
                  {{ $t('documents.toolbar.button.move')}}
                </el-rectangle-button>
              </el-rectangle-button-child>
            </el-rectangle-button-container>
          </el-table-cell>
        </el-table-row>

        <!-- document -->
        <el-table-row
          v-for="doc in files.filter(x => x.type === 'file')"
          :key="doc.id"
          class="ep-table__row h-16"
          tabindex="0"
          @click.native="$router.push({ name: 'document.details',
                                          params: { folder: currentFolder, doc } })"
        >
          <!-- checkbox -->
          <el-table-cell data-type="input" @click.native.stop="select(doc)">
            <input class="el-checkbox"
                   type="checkbox"
                   :title="$t('documents.selectedCheckbox') + ' ' + doc.name"
                   :aria-label="$t('documents.selectedCheckbox') + ' ' + doc.name"
                   :checked="selected.includes(doc)" />
          </el-table-cell>
          <!-- doc logo -->
          <el-table-cell data-type="input" aria-hidden="true">
            <!-- eslint-disable-next-line -->
            <span :class="['icon-document', 'icon--table--orange', { 'icon--notif': doc.isNew }]" style="position: relative;">
              <span v-if="doc.nbSignatures > 0" class="icon-signed-doc ep-icon-doc-signed">
                <span class="ep-icon-doc-signed__text">{{ doc.nbSignatures }}</span>
              </span>
            </span>
          </el-table-cell>
          <!-- doc name -->
          <el-table-cell :main="true">
            <span v-if="doc !== fileRenamed">{{ doc.name }}</span>
            <form v-else @click.stop @submit.prevent="rename(doc, $refs.renameDoc[0].value)">
              <div class="el-input-group el-input-group--default-size">
                <input
                  ref="renameDoc"
                  type="text"
                  class="el-input-group__input"
                  :value="doc.name"
                  :placeholder="$t('documents.rename.placeholder')"
                  required
                  autofocus
                />
              </div>
              <button class="el-button">
                <span class="icon-save"></span>
              </button>
              <button
                class="el-button el-button--inverted"
                @click.prevent="fileRenamed = undefined"
              >
                <span class="icon-close"></span>
              </button>
            </form>
          </el-table-cell>
          <!-- doc deposite date -->
          <el-table-cell>
            {{ doc.depositDate.split(' ')[0].split('-').reverse().join('/') }}
          </el-table-cell>
          <!-- doc buttons -->
          <el-table-cell data-type="button" @click.native.stop>
            <el-rectangle-button-container  v-if="selected.length <= 1">

              <el-rectangle-button-child>
                  <el-rectangle-button-title slot="header">Options de fichier
                  </el-rectangle-button-title>
                <el-rectangle-button
                  @click.native="fileRenamed = doc"
                  :title="$t('documents.toolbar.button.rename')">
                {{ $t('documents.toolbar.button.rename') }}</el-rectangle-button>
                <el-rectangle-button v-if="doc.isNew" @click.native="markAsRead(doc.id, true)"
                   :title="$t('documents.toolbar.button.markAsRead')">
                  {{ $t('documents.toolbar.button.markAsRead')}}
                </el-rectangle-button>
                <el-rectangle-button v-else @click.native="markAsRead(doc.id, false)"
                  :title="$t('documents.toolbar.button.markAsUnread')"
                >
                  {{ $t('documents.toolbar.button.markAsUnread')}}
                </el-rectangle-button>
                <el-rectangle-button
                  @click.native="share(doc)"
                  :title="$t('documents.toolbar.button.share')"
                >{{ $t('documents.toolbar.button.share') }}</el-rectangle-button>
                  <el-rectangle-button
                @click.native="downloadFile(doc)"
                :title="$t('documents.toolbar.button.download')"
              >{{ $t('documents.toolbar.button.download') }}</el-rectangle-button>
                <el-rectangle-button @click.native="openMoveDialog(doc)"
                 :title="$t('documents.toolbar.button.move')">
                  {{ $t('documents.toolbar.button.move')}}
                </el-rectangle-button>
                <el-rectangle-button
                  @click.native="showModal('deleteFile',doc)"
                  :title="$t('documents.toolbar.button.delete')"
                >{{ $t('documents.toolbar.button.delete') }}</el-rectangle-button>
              </el-rectangle-button-child>
            </el-rectangle-button-container>
          </el-table-cell>
        </el-table-row>
      </el-table-body>
    </el-table>
    <div class="el-subtitle" v-if="!files.length">
      {{ $t('documents.toolbar.search.no-match') }}
    </div>

    <!--modal Delete-->
    <div class="el-dialog" v-show='modalVisible' :aria-hidden="!modalVisible">
      <div class="el-dialog__container">
        <div class="el-panel">
          <template v-if="deleteFunction === 'deleteFile' || selected.length === 1">
            <h2 className="el-title">{{$t('dialog.item.delete.file')}}</h2>
          </template>
          <template v-else-if="deleteFunction ===  'deleteSelected'">
            <h2 className="el-title">{{$t('dialog.item.delete.files')}}</h2>
          </template>
          <template v-else>
            <h2 className="el-title">{{$t('dialog.item.delete.folder')}}</h2>
          </template>
          <form v-on:submit.prevent="deleteData">
          <div class="ep-button--panel-middle" >
            <button
              class="el-button el-button--inverted"
              @click.prevent="showModal"
            >{{ $t('sharing.create.button.cancel') }}</button>
            <button
              class="el-button e2e-validsuppressFolder"
              type="submit"
            >{{ $t('sharing.create.button.delete') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ep-layout>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import downloadjs from 'downloadjs';
import documentApi from '@/api/DocumentApi';
import trackingServices from '@/plugins/posthog';
import FolderApi from '@/api/FolderApi';
import EpLayout from './Layout.vue';

export default {

  name: 'FoldersIndex',

  props: {

    noReset: {
      type: Boolean,
      default: false,
    },

    doReload: {
      type: Boolean,
      default: false,
    },
  },


  components: {
    EpLayout,
  },

  data() {
    return {
      cols: [
        { text: this.$i18n.t('documents.name'), id: 'name' },
        { text: this.$i18n.t('documents.date'), id: 'depositDate' },
      ],
      modalVisible: false,
      deleteFunction: '',
      dataDeleted: undefined,
      // {{ $t('documents.name') }}{{ $t('documents.date') }}
      selected: [],
      allSelected: false,
      documents: [],
      search: '',
      // breadcrumbs: [{ id: null, name: this.$i18n.t('documents.perso') }],
      currentFolder: {},
      files: [],
      newFolderDialog: false,
      newFolderName: '',
      fileRenamed: undefined,
      uploadFiles: [],
      icons: [
        {
          value: 'administratif',
          code: 'administrative',
          text: this.$i18n.t('collector.asideMenu.admin'),
        },
        {
          value: 'banque',
          code: 'banque',
          text: this.$i18n.t('collector.asideMenu.banque'),
        },
        {
          value: 'course',
          code: 'shopping',
          text: this.$i18n.t('collector.asideMenu.shopping'),
        },
        {
          value: 'e-commerce',
          code: 'ecommerce',
          text: this.$i18n.t('collector.asideMenu.e-com'),
        },
        {
          value: 'famille',
          code: 'family',
          text: this.$i18n.t('collector.asideMenu.family'),
        },
        {
          value: 'internet',
          code: 'internet',
          text: this.$i18n.t('collector.asideMenu.internet'),
        },
        {
          value: 'lettre',
          code: 'letters',
          text: this.$i18n.t('collector.asideMenu.letter'),
        },
        {
          value: 'lettre',
          code: 'recordedLetter',
          text: this.$i18n.t('collector.asideMenu.rLetter'),
        },
        {
          value: 'loisir',
          code: 'hobby',
          text: this.$i18n.t('collector.asideMenu.loisir'),
        },
        {
          value: 'maison',
          code: 'home',
          text: this.$i18n.t('collector.asideMenu.home'),
        },
        {
          value: 'folder',
          code: 'myFolders',
          text: this.$i18n.t('collector.asideMenu.myFolders'),
        },
        {
          value: 'signataires',
          code: 'myEmployers',
          text: this.$i18n.t('collector.asideMenu.employer'),
        },
        {
          value: 'sante',
          code: 'health',
          text: this.$i18n.t('collector.asideMenu.health'),
        },
        {
          value: 'transport',
          code: 'transports',
          text: this.$i18n.t('collector.asideMenu.transport'),
        },
        {
          value: 'online',
          code: 'onLineServices',
          text: this.$i18n.t('collector.asideMenu.online'),
        },
        {
          value: 'phone',
          code: 'telephonie',
          text: this.$i18n.t('collector.asideMenu.phone'),
        },
        {
          value: 'notClassified',
          code: 'notClassified',
          text: this.$i18n.t('collector.asideMenu.notClassified'),
        },
      ],
      moveDialogOpen: false,
      selectedMoveFolder: undefined,
      elementToMove: undefined,
      modalFolders: [],
      modalBreadcrumbs: [
        {
          type: 'folder',
          name: this.$i18n.t('document.modal.move.personnal.space'),
          code: 'accueil',
          folderId: '0',
        },
      ],
      currentModalFolder: {
        type: 'folder',
        name: this.$i18n.t('document.modal.move.personnal.space'),
        code: 'accueil',
        folderId: '0',
      },
    };
  },

  watch: {
    currentFolder() {
      if (this.search) {
        this.files = this.searchAllFiles(this.currentFolder.children);
        this.checkSelectAll();
      } else {
        this.files = this.currentFolder.children !== undefined ? this.currentFolder.children : [];
        this.checkSelectAll();
      }
      this.clearSelected();
    },
    currentModalFolder() {
      if (this.currentModalFolder.folderId === '0') {
        this.modalFolders = Object.assign([], this.all);
      } else {
        this.modalFolders = this.currentModalFolder.children;
      }
    },
    search() {
      if (this.search) {
        this.files = this.searchAllFiles(this.currentFolder.children);
      } else {
        this.files = this.currentFolder.children;
      }
    },
    newFolderDialog() {
      this.newFolderName = '';
    },
  },

  computed: {
    ...mapState({
      all: state => state.documents.all,
      sideMenu: state => state.application.sideMenu,
      breadcrumbs: state => state.application.breadcrumbs,
    }),
    ...mapGetters({
      firstBreadcrumb: 'application/getFirstBreadcrumb',
      lastBreadcrumb: 'application/getLastBreadcrumb',
    }),

    textTitle() {
      if (this.elementToMove) { return this.$t('documents.modal.move.title'); }
      return this.$t('documents.modal.move.title.multipleFiles');
    },
    textFiles() {
      if (this.elementToMove) { return this.elementToMove.name; }
      return '';
    },

  },


  methods: {
    ...mapActions({
      fetchFilesAndFolders: 'documents/all',
      getLastDoc: 'documents/getLastDoc',
      addBreadcrumb: 'application/addBreadcrumb',
      removeLastBreadcrumb: 'application/removeLastBreadcrumb',
      setBreadcrumbs: 'application/setBreadcrumbs',
      resetBreadcrumbs: 'application/resetBreadcrumbs',
      renameFile: 'documents/renameFile',
      renameFolder: 'documents/renameFolder',
      downloadMyFile: 'documents/downloadMyFile',
      downloadMultipleFilesAsZip: 'documents/downloadMultipleFiles',
      deleteMyFolder: 'documents/deleteMyFolder',
      deleteMyFile: 'documents/deleteMyFile',
      moveFolder: 'documents/moveFolder',
      moveFile: 'documents/moveFile',
      createMyFolder: 'documents/createMyFolder',
      getUsageDatas: 'user/getUsageDatas',
    }),

    countFiles() {
      return this.files.filter(x => x.type === 'file').length;
    },

    select(file) {
      if (this.selected.includes(file)) {
        this.selected.splice(this.selected.indexOf(file), 1);
      } else {
        this.selected.push(file);
      }
      this.checkSelectAll();
    },
    selectAll() {
      if (!this.allSelected) {
        for (let i = 0; i < this.files.length; i += 1) {
          if (!this.selected.includes(this.files[i])) {
            if (this.files[i].type === 'file') {
              this.selected.push(this.files[i]);
            }
          }
        }
      } else {
        for (let i = 0; i < this.files.length; i += 1) {
          this.selected.splice(this.selected.indexOf(this.files[i]), 1);
        }
      }
      this.checkSelectAll();
    },
    clearSelected() {
      this.selected = [];
      this.checkSelectAll();
    },
    checkSelectAll() {
      let res = true;
      for (let i = 0; i < this.files.length; i += 1) {
        if (this.files[i].type === 'file' && !this.selected.includes(this.files[i])) {
          res = false;
        }
      }
      if (this.files.length === 0) {
        res = false;
      }
      this.allSelected = res;
    },
    searchAllFiles(folder) {
      let res = [];
      for (let i = 0; i < folder.length; i += 1) {
        if (folder[i].name.toLowerCase().includes(this.search.toLowerCase())) {
          res.push(folder[i]);
        }
        if (folder[i].children) {
          res = res.concat(this.searchAllFiles(folder[i].children));
        }
      }
      return res;
    },
    rename(item, name) {
      if (item.type === 'folder') {
        const folder = {
          folderID: item.folderId,
          name,
        };
        this.renameFolder(folder).then(() => {
          this.reload();
        });
      }
      if (item.type === 'file') {
        const file = {
          fileID: item.id,
          name,
        };
        this.renameFile(file).then(() => {
          this.reload();
        });
      }
      this.fileRenamed = undefined;
    },
    share(doc = undefined) {
      const share = doc ? [doc] : this.selected;
      this.$router.push({ name: 'sharings.create', params: { share } });
    },

    downloadFile(file) {
      this.downloadMyFile(file.id).then((response) => {
        let filename = file.name;
        if (!filename.endsWith(`.${file.extension}`)) {
          filename = `${filename}.${file.extension}`;
        }
        downloadjs(response, filename);
        if (file.isNew) {
          this.reload();
          this.getUsageDatas();
        }
      });
    },

    downloadMultipleFiles() {
      if (this.selected.length === 1) {
        this.downloadFile(this.selected[0]);
        return;
      }

      this.downloadMultipleFilesAsZip(this.selected.map(document => document.id))
        .then((response) => {
          downloadjs(response, 'documents-edocperso.zip');
          this.reload();
        });
    },

    deleteFolder(folder) {
      this.deleteMyFolder(folder.folderId).then(() => this.reload());
    },
    deleteFile(file) {
      this.deleteMyFile(file.id).then(() => this.reload());
    },
    moveSelectedTo(id) {
      const promises = [];
      for (let i = 0; i < this.selected.length; i += 1) {
        const item = this.selected[i];
        if (item.type === 'folder') {
          promises.push(this.moveFolder({ folderID: item.folderId, name: item.name, id }));
        } else {
          promises.push(this.moveFile({ itemid: item.id, id }));
        }
      }
      return Promise.all(promises);
    },
    createFolder() {
      this.newFolderDialog = false;
      const o = {
        FolderID: this.currentFolder.folderId,
        NewFolderName: this.newFolderName,
      };
      trackingServices.folderCreated();
      this.createMyFolder(o)
        .then((response) => {
          if (this.selected.length > 0) {
            this.moveSelectedTo(response.content.id)
              .then(() => {
                this.selected = [];
                Promise.resolve(response)
                  .then(() => this.reload());
              });
          } else {
            Promise.resolve(response)
              .then(() => this.reload());
          }
        });
    },
    markAsRead(documentId, isRead) {
      documentApi.markAsRead(documentId, isRead).then(() => this.reload());
    },
    markSelectedAsRead(value) {
      const promises = [];
      for (let i = 0; i < this.selected.length; i += 1) {
        const item = this.selected[i];
        if (item.type === 'file') {
          promises.push(documentApi.markAsRead(item.id, value));
        }
      }
      Promise.all(promises)
        .then((responses) => {
          const errors = [];
          for (let i = 0; i < responses.length; i += 1) {
            const response = responses[i];
            if (response.code !== 0) {
              errors.push(response);
            }
          }
          if (errors.length === 0) {
            this.$store.dispatch('application/openSnackbar', {
              message: value ? this.$i18n.t('documents.message.file.markAsRead.success') : this.$i18n.t('documents.message.file.markAsUnread.success'),
              time: 10,
              type: 'success',
              centered: true,
            });
          } else {
            this.$store.dispatch('application/openSnackbar', {
              message: value ? this.$i18n.t('documents.message.file.markAsRead.fail') : this.$i18n.t('documents.message.file.markAsUnread.fail'),
              time: 10,
              type: 'error',
              centered: true,
            });
          }
          this.reload();
        });
    },
    deleteSelected() {
      const promises = [];
      for (let i = 0; i < this.selected.length; i += 1) {
        const item = this.selected[i];
        if (item.type === 'folder') {
          promises.push(FolderApi.deleteById(item.folderID));
        } else {
          promises.push(documentApi.deleteById(item.id));
        }
      }
      let flag = 0;
      Promise.all(promises).then((responses) => {
        for (let i = 0; i < responses.length; i += 1) {
          if (responses[i].code !== 0) flag += 1;
        }

        if (flag === 0) {
          this.$store.dispatch('application/openSnackbar', {
            message: this.$i18n.t('documents.message.folder.delete.success'),
            time: 10,
            type: 'success',
            centered: true,
          });
          this.reload();
        } else {
          this.$store.dispatch('application/openSnackbar', {
            message: this.$i18n.t('documents.message.folder.delete.prevented'),
            time: 10,
            type: 'error',
            centered: true,
          });
          this.reload();
        }
      });
    },
    handleFileUpload() {
      this.uploadFiles = this.$refs.uploadFiles.files;
      this.submitFiles();
    },
    submitFiles() {
      const promises = [];
      for (let i = 0; i < this.uploadFiles.length; i += 1) {
        const file = this.uploadFiles[i];
        const content = {
          json: {
            sessionId: this.$store.state.auth.session.token,
            description: file.name,
            folderId: this.currentFolder.folderId,
            extension:
              file.name.substring(
                file.name.lastIndexOf('.') + 1,
                file.name.length,
              ) || file.name,
          },
          fileContent: file,
        };
        promises.push(documentApi.create(content));
        trackingServices.documentAdded();
      }
      Promise.all(promises)
        .then((responses) => {
          const errors = [];
          for (let i = 0; i < responses.length; i += 1) {
            const response = responses[i];
            if (response.code !== 0) {
              errors.push(response);
            }
          }
          if (errors.length === 0) {
            this.reload().then(() => {
              this.selected = [];
              this.$store.dispatch('application/openSnackbar', {
                message: this.$i18n.t('documents.message.file.add.success'),
                time: 10,
                type: 'success',
                centered: true,
              });
            });
          } else {
            this.reload().then(() => {
              this.$store.dispatch('application/openSnackbar', {
                message: this.$i18n.t('documents.message.file.add.fail'),
                time: 10,
                type: 'error',
                centered: true,
              });
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(`FAILURE!! + ${error}`);
          this.$store.dispatch('application/openSnackbar', {
            message: this.$i18n.t('documents.message.file.add.fail'),
            time: 10,
            type: 'error',
            centered: true,
          });
        });
    },
    // change folder tab
    changeTab(value) {
      this.currentFolder = value;
      this.setBreadcrumbs([value]);
    },
    // got to the clicked folder
    goToNext(folder) {
      this.clearSelected();
      this.currentFolder = folder;
      this.addBreadcrumb(folder);
    },
    // got to the clicked folder in modal
    goToModalNext(folder) {
      this.currentModalFolder = folder;
      this.modalBreadcrumbs.push(folder);
    },
    // got to current folder's parent
    goToPrevious() {
      this.clearSelected();
      this.currentFolder = this.breadcrumbs[this.breadcrumbs.length - 2];
      this.removeLastBreadcrumb();
    },

    goToModalPrevious() {
      this.currentModalFolder = this.modalBreadcrumbs[this.modalBreadcrumbs.length - 2];
      this.modalBreadcrumbs.pop();
    },
    // got to a folder from breadcrumb
    goToFolder(folder) {
      this.clearSelected();
      this.currentFolder = folder;
      while (this.breadcrumbs[this.breadcrumbs.length - 1] !== folder) {
        this.removeLastBreadcrumb();
      }
    },
    // init currentFolder to last folder visited
    initToFolder() {
      this.currentFolder = this.breadcrumbs[this.breadcrumbs.length - 1];
      if (this.doReload) {
        this.reload();
      }
    },
    // init default folder (reset)
    initDefaultFolder() {
      // eslint-disable-next-line
      this.currentFolder = this.all[0];
      if (this.currentFolder === undefined) {
        this.currentFolder = {
          name: 'Administratif',
        };
      }

      if (!this.noReset) {
        this.setBreadcrumbs([this.currentFolder]);
      }
      this.files = this.currentFolder.children;
    },
    reload() {
      return this.fetchFilesAndFolders().then(() => {
        this.getLastDoc();
        const newBreadcrumbs = [];
        let current = this.all;
        for (let i = 0; i < this.breadcrumbs.length; i += 1) {
          newBreadcrumbs.push(
            current.find(x => x.folderId === this.breadcrumbs[i].folderId),
          );
          current = current.find(
            x => x.folderId === this.breadcrumbs[i].folderId,
          ).children;
        }
        this.setBreadcrumbs(newBreadcrumbs);
        this.currentFolder = this.breadcrumbs[this.breadcrumbs.length - 1];
      });
    },

    openMoveDialog(elmt) {
      if (elmt) {
        this.elementToMove = elmt;
        this.moveDialogOpen = true;
        this.modalFolders = Object.assign([], this.all);
      } else {
        this.moveDialogOpen = true;
        this.modalFolders = Object.assign([], this.all);
      }
    },

    closeMoveDialog() {
      this.elementToMove = undefined;
      this.selectedMoveFolder = undefined;
      this.moveDialogOpen = false;
      this.currentModalFolder = {
        type: 'folder',
        name: this.$i18n.t('document.modal.move.personnal.space'),
        code: 'accueil',
        folderId: '0',
      };
      this.modalBreadcrumbs = [
        {
          type: 'folder',
          name: this.$i18n.t('document.modal.move.personnal.space'),
          code: 'accueil',
          folderId: '0',
        },
      ];
    },

    goToModalFolder(folder) {
      this.currentModalFolder = folder;
      while (this.modalBreadcrumbs[this.modalBreadcrumbs.length - 1] !== folder) {
        this.modalBreadcrumbs.pop();
      }
    },
    updateSelectedMoveFolder(folder) {
      this.selectedMoveFolder = folder.folderId;
    },

    moveElement() {
      if (this.selectedMoveFolder) {
        if (this.selected.length >= 1) {
          const promises = [];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selected.length; i++) {
          // eslint-disable-next-line max-len
            promises.push(this.moveFile({ itemid: this.selected[i].id, id: this.selectedMoveFolder }));
          }
          Promise.all(promises).then(() => {
            this.reload();
            this.closeMoveDialog();
          });
        } else if (this.elementToMove.type === 'file') {
          this.moveFile({ itemid: this.elementToMove.id, id: this.selectedMoveFolder })
            .then(() => {
              this.reload();
              this.closeMoveDialog();
            });
        } else if (this.elementToMove.type === 'folder') {
          this.moveFolder({
            folderID: this.elementToMove.folderId,
            name: this.elementToMove.name,
            id: this.selectedMoveFolder,
          })
            .then(() => {
              this.reload();
              this.closeMoveDialog();
            });
        }
      } else {
        this.$store.dispatch('application/openSnackbar', {
          message: this.$i18n.t('documents.modal.move.error.select.one.file'),
          time: 10,
          type: 'error',
          centered: true,
        });
      }
    },

    showModal(nameFunction, data) {
      this.modalVisible = !this.modalVisible;

      if (data !== undefined) {
        this.dataDeleted = data;
      }
      if (nameFunction !== undefined) {
        this.deleteFunction = nameFunction;
      } else {
        this.deleteFunction = '';
      }
    },

    deleteData() {
      if (this.deleteFunction !== '') {
        this[this.deleteFunction](this.dataDeleted);
        this.showModal();
        this.deleteFunction = '';
        this.dataDeleted = undefined;
      } else {
        this.showModal();
        this.deleteFunction = '';
        this.dataDeleted = undefined;
      }
    },
  },

  mounted() {
    if (!this.all.length) {
      this.fetchFilesAndFolders().then(() => {
        this.initDefaultFolder();
      });
    } else {
      this.initDefaultFolder();
    }
    if (this.noReset) {
      this.initToFolder();
    }
  },
};
</script>
